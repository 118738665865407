<template>
  <div class="pt-30">
    <div class="content-box bg-white box-shadow mb-30">
      <div class="tabs">
        <div class="tab px-25 pt-20 active">
          <!-- :class="{ active: coin == 'USDT' }"
          @click="onTab('USDT')" -->
          USDT
        </div>
        <!-- <div
          class="tab px-25 pt-20"
          :class="{ active: coin == 'BTC' }"
          @click="onTab('BTC')"
        >
          BTC {{ $t("look") }}
        </div>
        <div
          class="tab px-25 pt-20"
          :class="{ active: coin == 'ETH' }"
          @click="onTab('ETH')"
        >
          ETH {{ $t("look") }}
        </div> -->

        <div class="search_box" style="margin-left: auto">
          <el-input
            :placeholder="$t('search_text')"
            prefix-icon="el-icon-search"
            @keyup.enter.native="searchFn"
            v-model="search_text"
          >
          </el-input>
        </div>
      </div>
      <el-row
        :gutter="20"
        type="flex"
        justify="center"
        align="middle"
        class="font-14 color-gray px-20 py-15"
      >
        <el-col :span="4">{{ $t("home.pair") }}</el-col>
        <el-col :span="4">{{ $t("home.price") }}</el-col>
        <el-col :span="4">{{ $t("home.change") }}</el-col>
        <el-col :span="4">24H {{ $t("home.high") }}</el-col>
        <el-col :span="4">24H {{ $t("home.low") }}</el-col>
        <!-- <el-col :span="4">24H {{ $t("home.finished") }}</el-col> -->
        <el-col class="text-right" :span="3">{{ $t("home.deal") }}</el-col>
      </el-row>
      <el-row
        v-for="(item, index) in market"
        :key="index"
        :gutter="20"
        type="flex"
        justify="center"
        align="middle"
        class="market-body px-20 py-15 border-top"
      >
        <el-col :span="1">
          <div
            v-if="item.logo"
            class="icon icon-style-yuan icon-stars"
            :style="{ backgroundImage: 'url(' + item.logo + ')' }"
          />
          <div
            v-else
            class="icon icon-style-yuan icon-stars"
            :class="'icon-coin-' + item.pair.toLowerCase()"
          />
        </el-col>
        <el-col :span="3"
          ><span
            class="is-link color-p-h"
            @click="$nav.push('/new_market/' + item.pair + '/' + item.coin)"
            >{{ item.pair.toUpperCase() }} / {{ item.coin.toUpperCase() }}</span
          ></el-col
        >
        <el-col :span="4">{{ item.lasest_price }}</el-col>
        <el-col :span="4" class="d-flex-align-center">
          <el-tag
            v-if="item.change > 0"
            class="change"
            type="success"
            effect="dark"
            size="medium"
            >+{{ item.change.toFixed(2) }}%</el-tag
          >
          <el-tag
            v-else-if="item.change == 0"
            class="change"
            type="warning"
            effect="dark"
            size="medium"
            >0.00%</el-tag
          >
          <el-tag
            v-else
            class="change"
            type="danger"
            effect="dark"
            size="medium"
            >{{ item.change.toFixed(2) }}%</el-tag
          >
          <i class="el-icon-top ml-5" :class="{ 'd-none': !item.showUp }" />
          <i
            class="el-icon-bottom ml-5"
            :class="{ 'd-none': !item.showDown }"
          />
        </el-col>
        <el-col :span="4">{{ item.high }}</el-col>
        <el-col :span="4">{{ item.low }}</el-col>
        <!-- <el-col :span="4">{{ item.volume_24h }}</el-col> -->
        <el-col class="deal text-right" :span="3">
          <el-button
            type="primary"
            size="small"
            plain
            class="bg-t"
            icon="el-icon-s-data"
            @click="$nav.push('/new_market/' + item.pair + '/' + item.coin)"
            >{{ $t("look") }}</el-button
          >
        </el-col>
      </el-row>
      <div
        v-if="market.length < 1 && marketFlag == false"
        v-loading="true"
        element-loading-background="#00000000"
        class="p-50"
      />
      <!-- 分頁 -->
      <div class="d-flex-center">
        <el-pagination
          class="pt-20 pb-20"
          :hide-on-single-page="true"
          background
          layout="prev, pager, next"
          :page-size="pageSize"
          :current-page="page"
          :total="total"
          @current-change="onPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import coinLogo from "@/assets/coin/logo";

export default {
  data() {
    return {
      // banner: this.$t("home.banner"),
      coinLogo,
      // coin: this.$store.state.marketCoin,
      coin: "USDT",
      noticeList: [],
      marketOld: null,
      market: [],
      showUp: false,
      showDown: false,
      loading: false,
      timer: null,
      dataVersion: 0,

      //新增
      marketLength: 0,
      socket1: null,

      //搜索
      search_text: "",

      //分頁
      pageSize: 10,
      page: 1,
      total: 0,
      marketFlag: false,
    };
  },
  watch: {
    // coin: function () {
    //   this.market = [];
    //   if (["BTC", "ETH", "USDT"].indexOf(this.coin) < 0) return;
    //   this.$store.commit("marketCoin", this.coin);
    //   this.tabChange(true);
    // },
  },
  created: function () {
    this.fatchNotice();
    this.tabChange(true);
    // this.timer = window.setInterval(() => {
    //   if (document.hidden) return;
    //   if (["BTC", "ETH", "USDT"].indexOf(this.coin) < 0) return;
    //   this.tabChange(false);
    // }, 2000);
  },
  beforeDestroy: function () {
    window.clearInterval(this.timer);
  },
  destroyed: function () {
    this.socket1.close(); //离开路由之后断开websocket连接
  },
  methods: {
    // 搜索
    searchFn() {
      this.market = [];
      this.marketLength = 0;
      this.marketFlag = false;
      this.page = 1;
      this.tabChange();
    },

    //分頁
    onPage(e) {
      this.marketFlag = false;
      this.page = e;
      this.market = [];
      this.marketLength = 0;
      this.tabChange();
    },

    fatchNotice: async function () {
      const { data } = await this.$request.post("v1/notice");
      this.noticeList = data;
    },
    onTab: function (active) {
      this.coin = active;
    },

    // tabChange: function (ignoreLoadingStatus = false) {
    //     if (this.loading && !ignoreLoadingStatus) return
    //     this.dataVersion++
    //     let version = Number(this.dataVersion)
    //     this.loading = true
    //     this.$requestNotState.get('market_detail/'+this.coin).then(({ data }) => {
    //         this.loading = false
    //         if(version != this.dataVersion) {
    //             return
    //         }
    //         if(this.market.length > 0){
    //             for(const index in data){
    //                 if(this.market[index]){
    //                     if(data[index].lasest_price > this.market[index].lasest_price){
    //                         data[index].showUp = true
    //                     }else if(data[index].lasest_price < this.market[index].lasest_price){
    //                         data[index].showDown = true
    //                     }
    //                 }
    //             }
    //         }
    //         this.market = data
    //         window.setTimeout(() => {
    //             for(const item of this.market){
    //                 item.showUp = false
    //                 item.showDown = false
    //             }
    //         }, 600)
    //     }).catch(() => {
    //         this.loading = false
    //     })
    // },

    // fetchMarket: function () {
    //   if (this.marketLoading) return;
    //   let url = "v1/symbol/getList";
    //   let postData = {
    //     coin: this.marketCoin,
    //   };
    //   this.$request
    //     .post(url, postData)
    //     .then((res) => {
    //       this.market = res.data;
    //       this.marketLength = res.data.length;

    //       let arr = {};
    //       if (res.data) {
    //         res.data.forEach(function (item, index) {
    //           arr[item.pair + "" + item.coin] = item;
    //         });
    //       }
    //       this.market = arr;
    //       this.marketLoading = false;

    //       //wss實時請求
    //       this.socket1 = this.$io("ws://47.243.105.117:9502/symbol", {
    //         query: {},
    //         transports: ["websocket", "polling"],
    //         timeout: 5000,
    //         // pingTimeout: 20000,
    //         // pingInterval: 25000,
    //       });

    //       this.socket1.on("connect_error", (reason) => {
    //         console.log(reason);
    //       });

    //       this.socket1.on("connect", (socket) => {
    //         // ws连接已建立，此时可以进行socket.io的事件监听或者数据发送操作
    //         console.log("ws 已连接");

    //         //全部报价
    //         this.socket1.emit("symbol_price_list");
    //         this.socket1.on("symbol_price_list", (res) => {
    //           this.market[JSON.parse(res).pair + "" + JSON.parse(res).coin]
    //             ? (this.market[
    //                 JSON.parse(res).pair + "" + JSON.parse(res).coin
    //               ] = JSON.parse(res))
    //             : "";
    //           this.market = JSON.parse(JSON.stringify(this.market));
    //         });
    //       });

    //       this.socket1.on("disconnect", (reason) => {
    //         console.log(reason);
    //       });

    //       this.socket1.on("error", (msg) => {
    //         console.log("ws error", msg);
    //       });
    //     })
    //     .catch((e) => {
    //       this.marketLoading = false;
    //     });
    // },

    tabChange: function (ignoreLoadingStatus = false) {
      this.loading = true;
      this.$requestNotState
        .post("v1/symbol/getList", {
          coin: this.coin.toLowerCase(),
          is_new: 1,
          pair: this.search_text,
          page: this.page,
          pageSize: this.pageSize,
        })
        .then(({ data }) => {
          this.marketFlag = true;
          this.loading = false;
          // // cte
          // data.list

          this.market = data.list;
          this.marketLength = data.list.length;
          this.total = data.total;

          let arr = {};
          if (data.list) {
            data.list.forEach(function (item, index) {
              item.showUp = false;
              item.showDown = false;
              arr[item.pair + "" + item.coin] = item;
            });
          }
          this.market = arr;

          if (this.socket1) {
            this.socket1.close();
          }
          //wss實時請求
          // this.socket1 = this.$io("ws://47.243.105.117:9502/symbol", {
          this.socket1 = this.$io(this.$wsn+"/symbol", {
            query: {},
            transports: ["websocket", "polling"],
            timeout: 5000,
            // pingTimeout: 20000,
            // pingInterval: 25000,
          });

          this.socket1.on("connect_error", (reason) => {
            console.log(reason);
          });

          this.socket1.on("connect", (socket) => {
            // ws连接已建立，此时可以进行socket.io的事件监听或者数据发送操作
            console.log("ws 已连接");

            //全部报价
            this.socket1.emit("symbol_price_list");
            this.socket1.on("symbol_price_list", (res) => {
              var new_arr = {};
              new_arr = JSON.parse(res);
              //操作箭头
              new_arr.showUp = false;
              new_arr.showDown = false;
              if (this.market[new_arr.pair + "" + new_arr.coin]) {
                if (
                  new_arr.lasest_price >
                  this.market[new_arr.pair + "" + new_arr.coin].lasest_price
                ) {
                  new_arr.showUp = true;
                  new_arr.showDown = false;
                } else if (
                  new_arr.lasest_price <
                  this.market[new_arr.pair + "" + new_arr.coin].lasest_price
                ) {
                  new_arr.showDown = true;
                  new_arr.showUp = false;
                } else {
                  new_arr.showUp = false;
                  new_arr.showUp = false;
                }
              }

              this.market[new_arr.pair + "" + new_arr.coin]
                ? (this.market[new_arr.pair + "" + new_arr.coin] = new_arr)
                : "";
              this.market = JSON.parse(JSON.stringify(this.market));
            });
          });

          //   if (version != this.dataVersion) {
          //     return;
          //   }
          //   if (this.market.length > 0) {
          //     for (const index in data) {
          //       if (this.market[index]) {
          //         if (
          //           data[index].lasest_price > this.market[index].lasest_price
          //         ) {
          //           data[index].showUp = true;
          //         } else if (
          //           data[index].lasest_price < this.market[index].lasest_price
          //         ) {
          //           data[index].showDown = true;
          //         }
          //       }
          //     }
          //   }
          //   this.market = data;

          //   window.setTimeout(() => {
          //     for (const item of this.market) {
          //       item.showUp = false;
          //       item.showDown = false;
          //     }
          //   }, 600);
        })
        .catch(() => {
          this.loading = false;
        });
    },

    onClick: function (item) {
      this.$nav.push("/trade/index/" + item.pair + "/" + item.coin);
    },
  },
};
</script>
<style scoped>
.index-top {
  /* background-image: url(~@/assets/index/top-bg.png); */
  height: 300px;
  background-repeat: no-repeat;
  background-size: auto 460px;
  background-position: center;
}
.icon-style-yuan {
  border-radius: 50%;
}
</style>
<style lang="scss" scoped>
.notice-bar {
  .link {
    display: inline-block;
    white-space: nowrap;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.tabs {
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid $bgColor;
  .tab {
    padding-bottom: 18px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
  }
  .active {
    color: $--color-primary;
    border-bottom: 2px solid $--color-primary;
  }
}
.market-body {
  .change {
    font-size: 14px !important;
    width: 80px;
    text-align: center !important;
  }
}
.services {
  .item {
    img {
      width: 100px;
      height: 100px;
    }
    p {
      font-size: 14px;
    }
  }
}

.search_box {
  padding: 0 20px;
  box-sizing: border-box;
}
</style>